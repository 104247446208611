.App {
  padding: 16px;
}

.App>main {
  display: flex;
  border: 1px solid #ddd;
}

.App>main>* {
  flex: 1;
  padding: 16px;
  border-right: 1px solid #ddd;
}

@media screen and (max-width: 900px) {
  .App>main {
    flex-direction: row;
    flex-wrap: wrap;
    border: unset;
    padding: 0;
    margin: 0;
  }

  .App>main>* {
    box-sizing: border-box;
    border: 1px solid #ddd;
    flex: unset;
    width: 50%;
    margin: 0;
  }

  .App>main>*:nth-last-child(1) {
    margin-right: 0;
  }
}

.App>main>*:nth-last-child(1) {
  border-right: unset;
}

.App .tag {
  font-size: 10px;
  color: white;
  padding: 2px 8px;
  border-radius: 8px;
  display: block;
  width: auto;
  margin-bottom: 2px;
}

.App .danger {
  background-color: orangered;
}

.App .warning {
  color: black;
  background-color: orange;
}

.App .success {
  background-color: green;
}

.AnprCamera input.NumberPlate {
  padding: 8px 0;
  border: 4px solid black;
  border-radius: 8px;
  background-color: yellow;
  font-family: 'Courier New', Courier, monospace;
  font-weight: bold;
  font-size: 32px;
  letter-spacing: 0.2em;
  width: auto;
  text-align: center;
  text-transform: uppercase;
  margin: 8px 0;
}

/* .App>main>.Weighbridge {
  flex: 2;
} */

.Weighbridge .MeasurementInputContainer {
  display: flex;
  flex-direction: row;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin: 1ch 0;
}

.Weighbridge .MeasurementInputContainer > * {
  margin-right: 1ch;
  padding: 1ch;
}

.Weighbridge input.ControlWeightIndicator {
  padding: 8px 0;
  border: 4px solid orangered;
  border-radius: 8px;
  background-color: black;
  font-family: 'Courier New', Courier, monospace;
  font-weight: bold;
  font-size: 32px;
  text-align: center;
  text-transform: uppercase;
  color: orangered;
  margin: 8px 0;
}

.Weighbridge .InputTypeSelector {
  display: flex;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 1ch;
  margin: 1ch 0;
}

.Weighbridge .InputTypeSelector > label {
  flex: 1;
}
.Weighbridge .InputTypeSelector > label > span {
  margin-left: 1ch;
}

.Weighbridge .DisplayContainer {
  position: relative;
}

.Weighbridge .DisplayContainer::after {
  font-family: 'Courier New', Courier, monospace;
  font-weight: bold;
  color: orangered;
  position: absolute;
  top: 24px;
  right: 16px;
}

.Weighbridge .ButtonControls {
  display: flex;
  flex-direction: row;
}

.DriverApp {
  display: flex;
  flex-direction: column;
}

.App button.button.danger {
  background-color: #ff3355;
}

.App button.button.success {
  background-color: #21be5d;
}

.App button.button:disabled {
  opacity: 0.5;
}

.App button.button {
  display: inline-block;
  padding: 0.7em 1.4em;
  margin: 0 0.3em 0.3em 0;
  border-radius: 0.15em;
  box-sizing: border-box;
  text-decoration: none;
  font-family: 'Roboto', sans-serif;
  text-transform: uppercase;
  font-weight: 400;
  color: #FFFFFF;
  background-color: #3369ff;
  box-shadow: inset 0 -0.6em 0 -0.35em rgba(0, 0, 0, 0.17);
  text-align: center;
  position: relative;
}

.App button.button:active {
  top: 0.1em;
}

@media all and (max-width:30em) {
  .App button.button {
    display: block;
    margin: 0.4em auto;
  }
}

.Configuration {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 1ch;
  padding: 1ch;
  border: 1px solid #ccc;
}

.Configuration>label {
  display: flex;
  flex: 1;
}

.Configuration>label>span {
  display: inline-block;
  width: 16ch;
  font-weight: bold;
  margin: 4px 0;
}

.Configuration>label input {
  flex: 1;
  padding: 0 8px;
}

.Configuration .DriverCredentialsContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.Configuration .DriverCredentialsContainer>label>span {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 10px;
  display: inline-block;
  width: 10ch;
}

.Configuration .DriverCredentialsContainer>label>input {
  width: 30ch;
}

.FirebaseAuthError {
  border: 2px solid rgb(255, 135, 135);
  padding: 16px;
  background-color: rgb(255, 230, 230);
  border-radius: 8px;
}

.FirebaseAuthError>span:nth-child(1) {
  display: block;
  font-weight: bold;
  margin-bottom: 8px;
}

.FirebaseAuthError>span:nth-child(2) {
  font-size: 0.8em;
}

.CredentialsDisplay {
  display: flex;
  flex-direction: column;
  margin: 8px 0;
  padding: 8px;
  border: 2px solid #ccc;
  border-radius: 8px;
  font-size: 10px;
}

.CredentialsDisplay>span:nth-child(1) {
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 4px;
}

.CredentialsDisplay>span:nth-child(2),
.CredentialsDisplay>span:nth-child(3) {
  font-family: 'Courier New', Courier, monospace;
  margin: 4px 0;
}

.DriverApp .DocketCard {
  display: flex;
  flex-direction: column;
  font-size: 10px;
  margin: 2ch 0;
  padding: 2ch;
  border: 1px solid #ccc;
  border-radius: 1ch;
  background-color: #eee;
}

.DriverApp .DocketCard>label {
  display: flex;
}

.DriverApp .DocketCard>label>span {
  display: inline-block;
  width: 16ch;
}
.DriverApp .DocketCard>label>input {
  flex: 1;
  padding: 1ch;
}

.CloudLog {
  display: flex;
  flex-direction: column;
}

.CloudLog .CloudTicketRow {
  display: flex;
  flex-direction: row;
  border-top: 1px solid #ccc;
  padding: 4px 0;
  margin: 4px 0;
}

.CloudLog .CloudTicketRow .Receipt {
  display: block;
  font-family: 'Courier New', Courier, monospace;
  width: 200px;
  font-size: 14px;
  padding: 4px 0;
  margin: 4px 0;
  text-align: center;
  background: #eee;
}

.CloudLog .CloudTicketRow .Receipt:after {
  background:
    linear-gradient(-45deg, transparent 16px, #eee 0),
    linear-gradient(45deg, transparent 16px, #eee 0);
  background-repeat: repeat-x;
  background-position: left bottom;
  background-size: 22px 32px;
  content: "";
  display: block;
  width: 100%;
  /* height: 32px; */
  position: relative;
  top: 64px;
  left: 0px;
  margin: 32px 0;
  margin-top: 0;
  padding: 32px 0;

}

.CloudLog .CloudTicketRow .Receipt .WeightsList .MeasurementRow {
  display: flex;
  flex-direction: column;
  border-top: 1px solid #ccc;
  padding: 4px 0;
  margin: 4px 0;
}

.CloudLog .CloudTicketRow .Receipt .WeightsList .MeasurementRow>span:nth-child(2) {
  font-weight: bold;
}

.CloudLog .CloudTicketRow .Docket {
  margin-left: 4px;
  display: flex;
  flex-direction: column;
  font-size: 10px;
  background-color: rgb(255, 255, 214);
  padding: 16px;
}

.CloudLog .CloudTicketRow .Docket>* {
  margin-bottom: 4px;
}

.CloudLog .CloudTicketRow .Docket>*::before {
  display: inline-block;
  width: 10ch;
}

.CloudLog .CloudTicketRow .Docket .supplier_name::before {
  content: "supplier";
}

.CloudLog .CloudTicketRow .Docket .docket_id::before {
  content: "docket id";
}

.CloudLog .CloudTicketRow .Docket .product_name::before {
  content: "product";
}

.CloudLog .CloudTicketRow .Docket .source::before {
  content: "source";
}

.CloudLog .CloudTicketRow .Docket .destination::before {
  content: "destination";
}

.CloudLog .CloudTicketRow .Docket .driver_name::before {
  content: "driver";
}

.CloudLog .CloudTicketRow .Docket .harvester_name::before {
  content: "harvester";
}

.CloudLog .CloudTicketRow .Docket .hauler_name::before {
  content: "hauler";
}

.CloudLog .Transaction {
  display: flex;
  flex-direction: column;
  border: 2px solid red;
}

.CloudLog .Transaction>*:nth-child(1) {
  font-size: 10px;
  font-family: 'Courier New', Courier, monospace;
}

.CloudLog .Transaction>* {
  margin: 4px;
}

.CloudLog .Transaction .Identities,
.CloudLog .Transaction .Identity {
  display: flex;
  flex-direction: column;
}

.CloudLog .Transaction .Identity {
  font-size: 12px;
  margin-left: 1em;
}

.SocketLogs {
  background-color: rgb(25, 31, 18);
  color: white;
  flex: 1;
  font-size: 12px;
  line-height: 1.6em;
  overflow: hidden;
  white-space: pre;
}

.SocketLogs > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.SocketLogs > div > * {
  flex: 1;
}
.SocketLogs > div > *:nth-child(2) {
  text-align: right;
  margin-right: 16px;
  cursor: pointer;
}
.SocketLogs > div > *:nth-child(2):hover {
  text-decoration: underline;
}

.SocketLogs > div > h1 {
  margin-left: 16px;
  font-size: 16px;
  /* padding: 4px 0; */
  /* margin-top: 16px; */
  /* margin-left: 16px; */
}

.SocketLogs>p {
  display: block;
  padding: 4px 16px;
  margin: 0;
  border-bottom: 1px solid rgb(92, 92, 92);
}

.TagsContainer {
  display: flex;
  margin-top: 1ch;
}
.TagsContainer > * {
  margin-right: 1ch;
}

.TagsContainer > *:nth-last-child(1) {
  margin-right: unset;
}

.HorizontalRadios {
  display: flex;
  flex-direction: row;
}
.HorizontalRadios > label {
  margin-right: 2ch;
  margin-bottom: 1ch;
}
.HorizontalRadios > label > span {
  margin-left: 1ch;
}

.Login>label {
  display: flex;
  flex-direction: column;
  font-size: 24px;
  padding: 2ch;
}
.Login>label>span {
  margin-right: 1ch;
  font-weight: bold;
  margin-bottom: 1ch;
}

.Login>label>input {
  flex: 1;
  font-size: 24px;
  padding: 2ch;
}

.DallasKey {
  display: flex;
  flex-direction: column;
}

.DallasKey input {
  font-size: 1.5em;
  padding: 1ch;
}

.DallasKey .InputTypeSelector {
  display: flex;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 1ch;
  margin: 1ch 0;
}

.DallasKey .InputTypeSelector > label {
  flex: 1;
}
.DallasKey .InputTypeSelector > label > span {
  margin-left: 1ch;
}